









import { Component, Vue } from 'vue-property-decorator'

import Page from '@/components/Page.vue'
import FaqItem from '@/components/FaqItem.vue';

const questions = {
  rider: [
  {
    question: "Wann kann man über die Fifty Fifty Taxi App mitfahren?",
    answer: "An Wochenenden und an gesetzlichen Feiertagen können alle zwischen 16 und 25 Jahren, jeweils von 20 bis 8 Uhr am Folgetag, für den halben Preis Taxi fahren. An Silvester sogar bis 12 Uhr am Neujahrstag."
  },
  {
    question: "Bis wann kann man Fahrten stornieren?",
    answer: "Fahrten können in der Regel bis 30 Minuten vor Fahrtbeginn von einem Fahrer als auch Mitfahrer storniert werden. Allerdings können die Zeiten regional abweichen. Die Zeiten können jeweils vom Land, der Gemeinde oder dem Landkreis über das Backend festgelegt werden."
  },
  {
    question: "Wer übernimmt die Taxi Fahrtkosten?",
    answer: "50 % des Fahrpreises zahlt der Fahrgast und 50% das Bundesland."
  },
  {
    question: "Muss ich mein Alter bei der Anmeldung als Mitfahrer verifizieren?",
    answer: "Als Mitfahrer musst Du dein Alter bei Fahrtantritt mit einem gültigen Dokument wie Reisepass oder Personalausweis verifizieren."
  },
  {
    question: "Sind Fahrten in ein anderes Bundesland möglich?",
    answer: "Fahrten in ein anderes Bundesland sind möglich, allerdings muß entweder der Zielort oder der Ausgangsort im ausführenden Bundesland liegen."
  },
  {
    question: "Wie viele Fahrten kann ich im Voraus anfragen?",
    answer: "3 Fahrten können im Voraus angefragt werden, sobald eine Fahrt erfolgt ist kann eine weitere angefragt werden."
  },
  {
    question: "Was ist der Unterschied zwischen Taxifahrten und Mietwagenfahrten?",
    answer: "Der Taxi Fahrpreis richtet sich nach den gefahrenen km, der Mietwagen-Fahrpreis wird im Voraus festgelegt, Mietwagenfahrten muss der Fahrgast vorher bestätigen."
  },
  {
    question: "Wie setzt sich der Fahrpreis zusammen?",
    answer: "Der Fahrpreis berechnet sich wie eine normale Taxifahrt nach den gefahrenen km, das Bundesland übernimmt jedoch die Hälfte des Fahrpreises."
  },
  {
    question: "Was mache ich wenn ich mein Passwort vergessen habe?",
    answer: 'Auf der Anmeldeseite befindet sich ein Button "Passwort vergessen" dort klicken und dann die bei der Registrierung verwendete Email eintragen, dann senden wir Dir einen Link zu über den du Dein neues Passwort setzen kannst.'
  },
  {
    question: "Wie lösche ich meinen Account?",
    answer: 'Im Menü befindet sich oberhalb des Logout Buttons ein Button "Account löschen", klicke diesen an und es öffnet sicht ein Fenster in welchem Du die Löschung des Accounts bestätigen oder abbrechen kannst.'
  },
  {
    question: "Wieviele Personen können mitfahren?",
    answer: "Maximale Personenanzahl ist 8, gib bei der Fahrtanfrage bitte die Zahl der Mitfahrer an um ein entsprechend großes Taxi zu bekommen."
  },
  {
    question: "Wie funktioniert der Chat?",
    answer: "Sobald Deine Fahrt bestätigt ist, kannst Du mit dem Fahrer über die integrierte Chatfunktion kommunizieren. Nach Stornierung der Fahrt ist dies nicht mehr möglich."
  },
  {
    question: "Kann ich einen Chat stumm schalten?",
    answer: "Auf der Chatseite kann der Chat über das Klingelicon oben rechts stummgeschaltet werden, oder die Stummschaltung aufgehoben werden. Ist der Chat stummgeschaltet erhältst Du keine Pushnotification über eingegangene Nachrichten."
  }
],
  driver: [
  {
    question: "Wie kann ich mich als Fahrer registrieren?",
    answer: "Ihr Taxiunternehmen muss sich auf der Webseite: https://www.5050brandenburg.de/register-company registrieren und kann dann die Fahrer eintragen."
  },
  {
    question: "Wann kann man über die Fifty Fifty Taxi App mitfahren?",
    answer: "An Wochenenden und an gesetzlichen Feiertagen können alle zwischen 16 und 25 Jahren, jeweils von 20 bis 8 Uhr am Folgetag, für den halben Preis Taxi fahren. An Silvester sogar bis 12 Uhr am Neujahrstag."
  },
  {
    question: "Bis wann kann man Fahrten stornieren?",
    answer: "Fahrten können in der Regel bis 30 Minuten vor Fahrtbeginn von einem Fahrer als auch Mitfahrer storniert werden. Allerdings können die Zeiten regional abweichen. Die Zeiten können jeweils vom Land, der Gemeinde oder dem Landkreis über das Backend festgelegt werden."
  },
  {
    question: "Wer übernimmt die Taxi Fahrtkosten?",
    answer: "50 % des Fahrpreises zahlt der Fahrgast und 50% das Bundesland."
  },
  {
    question: "Wie verifiziert der Fahrgast sein Alter?",
    answer: "Der Fahrgast muss sein Alter bei Fahrtantritt mit einem gültigen Dokument wie Reisepass oder Personalausweis verifizieren."
  },
  {
    question: "Sind Fahrten in ein anderes Bundesland möglich?",
    answer: "Fahrten in ein anderes Bundesland sind möglich, allerdings muß entweder der Zielort oder der Ausgangsort im ausführenden Bundesland liegen."
  },
  {
    question: "Wie viele Fahrten können im Voraus angefragt werden?",
    answer: "3 Fahrten können im Voraus angefragt werden, sobald eine Fahrt erfolgt ist kann eine weitere angefragt werden."
  },
  {
    question: "Was ist der Unterschied zwischen Taxifahrten und Mietwagenfahrten?",
    answer: "Der Taxi Fahrpreis richtet sich nach den gefahrenen km, der Mietwagen-Fahrpreis wird im Vorraus festgelegt. Mietwagenfahrten muss der Fahrgast vorher bestätigen."
  },
  {
    question: "Wie setzt sich der Fahrpreis zusammen?",
    answer: "Der Fahrpreis berechnet sich wie eine normale Taxifahrt nach den gefahrenen km, das Bundesland übernimmt jedoch die Hälfte des Fahrpreises."
  },
  {
    question: "Was mache ich wenn ich mein Passwort vergessen habe?",
    answer: 'Auf der Anmeldeseite befindet sich ein Button "Passwort vergessen" dort klicken und dann die bei der Registrierung verwendete Email eintragen, dann senden wir Ihnen einen Link zu über den ein neues Passwort gesetzt werden kann.'
  },
  {
    question: "Wie lösche ich meinen Account?",
    answer: 'Im Menü befindet sich oberhalb des Logout Buttons ein Button "Account löschen", wird dieser geklickt öffnet sicht ein Fenster in welchem die Löschung des Accounts bestätigt oder abgebrochen werden kann.'
  },
  {
    question: "Wieviele Personen können mitfahren?",
    answer: "Bei Fahrtanfrage kann der Fahrgast bis zu 8 Personen angeben."
  },
  {
    question: "Wie funktioniert der Chat?",
    answer: "Sobald eine Fahrt bestätigt ist kann über die integrierte Chatfunktion kommuniziert werden. Nach Stornierung der Fahrt ist dies nicht mehr möglich."
  },
  {
    question: "Kann ich einen Chat stumm schalten?",
    answer: "Auf der Chatseite kann der Chat über das Klingelicon oben rechts stummgeschaltet werden, oder die Stummschaltung aufgehoben werden. Ist der Chat stummgeschaltet werden keine Pushnotifikationen mehr versendet."
  },
  {
    question: "Wie bekomme ich die 50% Fahrtkosten vom Land erstattet?",
    answer: "Die mit Fifty Fifty durchgeführten Fahrten werden in der App gesammelt und am Ende des Monats bekommt Ihr Taxiunternehmen den Gesamtbetrag von der Taxigenossenschaft erstattet."
  }
],
}

@Component({
  components: {
    FaqItem,
    Page
  }
})
export default class FAQView extends Vue {
  // @ts-ignore
  faqQuestions = questions[this.$route.params.type]
}
